import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";
import Snackbar from "components/snackbar/Snackbar";
import {useState} from "react";
import {AssingDriverToDeliveryAction} from "service/carService/CarService";
import {GetDriverAction} from "../../../service/userService/UserService";
import AssignDriverToDeliveryForm, {
    GetDefaultAssignDriverToDeliveryForm
} from "../../../service/carService/models/AssignDriverToDeliveryForm";
import Select from "../../../components/inputs/Select";

interface props {
    deliveryHash: string;
    driverHash?: string;
}

const baseClass = "assign-driver-to-delivery";

const AssignDriverToDelivery = ({deliveryHash, driverHash}: props) => {
    const [form, setForm] = useState<AssignDriverToDeliveryForm>(GetDefaultAssignDriverToDeliveryForm(deliveryHash, driverHash));
    const [error, setError] = useState<string | null>(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const assignCarToDelivery = AssingDriverToDeliveryAction();
    const userAction = GetDriverAction();

    const submit = async () => {
        const response = await assignCarToDelivery.mutateAsync(form);
        setError(response.error);
        setShowSnackbar(true);
    };

    if (!userAction.isSuccess) {
        return null;
    }

    return (
        <>
            <div className={baseClass}>
                <h1>Assign car to delivery</h1>
                {userAction.isLoading ? (
                    <CircularProgress/>
                ) : (
                    <>
                        <Select
                            value={form.driverHash}
                            label={"select driver"}
                            setValue={e => setForm(prev => ({...prev, driverHash: e.target.value}))}
                            values={userAction.data?.map(d => ({key: d.hash, value: `${d.name} ${d.surname}`})) ?? []}
                            baseClass={baseClass}
                        />
                        <LoadingButton variant="outlined" color="success" onClick={submit} size="large">
                            Assign
                        </LoadingButton>
                    </>
                )}
            </div>
            <Snackbar
                message={error ? error : "Car assign to delivery"}
                setIsOpen={setShowSnackbar}
                variant={error ? "error" : "success"}
                isOpen={showSnackbar}
            />
        </>
    );
};

export default AssignDriverToDelivery;
