import React, { useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { StylesConfig, SingleValue, ActionMeta } from 'react-select';
import CreateLocationForm from 'service/location/models/CreateLocationForm';

interface PlaceOption {
    label: string;
    value: string;
    city?: string;
    country?: string;
    postalCode?: string;
    street?: string;
    no?: string;
    region?: string;
    lat: number;
    lon: number;
}

interface SearchProps {
    setForm: React.Dispatch<React.SetStateAction<CreateLocationForm>>;
}

const Search: React.FC<SearchProps> = ({ setForm }) => {
    const [inputValue, setInputValue] = useState<string>('');

    const fetchSuggestions = async (query: string) => {
        try {
            const response = await axios.get(
                `https://nominatim.openstreetmap.org/search?addressdetails=1&format=json&q=${query}`
            );
            return response.data.map((place: any) => ({
                label: place.display_name,
                value: place.display_name,
                city: place.address.city || '',
                country: place.address.country || '',
                postalCode: place.address.postcode || '',
                street: place.address.road || '',
                no: place.address.house_number || '',
                region: place.address.state || '',
                lat: parseFloat(place.lat),
                lon: parseFloat(place.lon)
            }));
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            return [];
        }
    };

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
    };

    const handleChange = (newValue: SingleValue<PlaceOption>, actionMeta: ActionMeta<PlaceOption>) => {
        const selectedOption = newValue as PlaceOption | null;
        if (selectedOption) {
            setForm((prevForm) => ({
                ...prevForm,
                city: selectedOption.city || '',
                country: selectedOption.country || '',
                postalCode: selectedOption.postalCode || '',
                street: selectedOption.street || '',
                no: selectedOption.no || '',
                region: selectedOption.region || '',
                lat: selectedOption.lat,
                lon: selectedOption.lon
            }));
            setInputValue(selectedOption.value);
        }
    };

    const customStyles: StylesConfig<PlaceOption, false> = {
        container: (provided) => ({
            ...provided,
            width: 200,
        }),
    };

    return (
        <div>
            <AsyncSelect
                isDisabled={false}
                styles={customStyles}
                value={inputValue ? { label: inputValue, value: inputValue } as PlaceOption : null}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                loadOptions={fetchSuggestions}
                onChange={handleChange}
                placeholder="Wpisz nazwę miejsca..."
                noOptionsMessage={() => 'Brak wyników'}
            />
        </div>
    );
};

export default Search;
