import {CircularProgress, Tooltip} from "@mui/material";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {GetDeliveryList} from "service/deliveryService/DeliveryService";
import {stringDeliveryStatus} from "service/deliveryService/models/DeliverStatus";
import Location from "service/location/models/Location";
import {LocationFullText, LocationShortText} from "utils/location/LocationUtils";
import Path, {GetPathWithParam} from "utils/route/Path";
import User from "../../../service/userService/models/User";
import { utcToZonedTime } from "date-fns-tz";

const timeZone = 'Europe/Warsaw';


const buildUserName = (user?: User) => {
    if(user === undefined || user === null){
        return ""
    }
    
    return `${user.name} ${user.surname}`
}

function convertUTCDateToLocalDate(date: Date) {
    return utcToZonedTime(date, timeZone)
}

// @ts-ignore
const columns: GridColDef[] = [
    {width: 250, field: "name", headerName: "Name"},
    {width: 200, field: "startDate", headerName: "Pickup date", renderCell: (params: any) => (<p> {convertUTCDateToLocalDate(new Date(params.value)).toString()} </p>)},
    {width: 200, field: "endDate", headerName: "Deliver date", renderCell: (params: any) => (<p> {convertUTCDateToLocalDate(new Date(params.value)).toString()} </p>)},
    {
        width: 400,
        field: "from",
        headerName: "From",
        renderCell: (params: any) => (
            <Tooltip title={LocationFullText(params.value as Location)}>
                <p>{LocationShortText(params.value as Location)}</p>
            </Tooltip>
        ),
    },
    {
        width: 400,
        field: "to",
        headerName: "To",
        renderCell: (params: any) => (
            <Tooltip title={LocationFullText(params.value as Location)}>
                <p>{LocationShortText(params.value as Location)}</p>
            </Tooltip>
        ),
    },
    {
        field: "status",
        headerName: "Status",
        renderCell: (params: any) => <p>{stringDeliveryStatus(params.value as number)}</p>,
    },
    {
        field: "driver",
        headerName: "Driver",
        renderCell: (param: any) => <p>{buildUserName(param.value as User)}</p>
    }
];

const baseClassName = "delivery-list";

const DeliveryList = () => {
    const {isLoading, data} = GetDeliveryList();
    const navigation = useNavigate();

    if (isLoading) {
        return <CircularProgress/>;
    }

    return (
        <div className={baseClassName}>
            <DataGrid
                getRowId={(row) => row.hash}
                columns={columns}
                disableDensitySelector={true}
                rows={data!}
                onRowClick={(param) => navigation(GetPathWithParam(Path.deliveryUpdate, param.id as string))}
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </div>
    );
};

export default DeliveryList;
